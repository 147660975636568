// Generated by Framer (9bf39e5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useRouteElementId, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/xZndidUCt";

const enabledGestures = {PAaBMCu9W: {hover: true}};

const cycleOrder = ["PAaBMCu9W"];

const variantClassNames = {PAaBMCu9W: "framer-v-1f1poee"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "PAaBMCu9W", title: pSiJQpYxO = "Kezdőlap", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "PAaBMCu9W", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const id1 = useRouteElementId("I_jehzBgP", "pH3J1KNIf")

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-dmZzi", sharedStyle.className, classNames)} style={{display: "contents"}}>
<Link href={{hash: id1, webPageId: "pH3J1KNIf"}} openInNewTab={false} smoothScroll><motion.a {...restProps} className={`${cx("framer-1f1poee", className)} framer-1bjsj5o`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"PAaBMCu9W"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"PAaBMCu9W-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-21ogod"} data-styles-preset={"xZndidUCt"} style={{"--framer-text-alignment": "center"}}>Kezdőlap</motion.p></React.Fragment>} className={"framer-xlm4rc"} layoutDependency={layoutDependency} layoutId={"xABXvUZLK"} style={{"--framer-paragraph-spacing": "0px", textShadow: "none"}} text={pSiJQpYxO} transformTemplate={transformTemplate} transition={transition} variants={{"PAaBMCu9W-hover": {"--extracted-r6o4lv": "rgb(255, 255, 255)", textShadow: "0px 0px 4px rgb(102, 120, 255)"}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({"PAaBMCu9W-hover": {children: <React.Fragment><motion.p className={"framer-styles-preset-21ogod"} data-styles-preset={"xZndidUCt"} style={{"--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Kezdőlap</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.a></Link>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-dmZzi [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-dmZzi * { box-sizing: border-box; }", ".framer-dmZzi .framer-1bjsj5o { display: block; }", ".framer-dmZzi .framer-1f1poee { height: 32px; overflow: visible; position: relative; text-decoration: none; width: 68px; }", ".framer-dmZzi .framer-xlm4rc { flex: none; height: auto; left: 50%; overflow: visible; position: absolute; top: 50%; white-space: pre; width: auto; }", ".framer-dmZzi .framer-v-1f1poee .framer-1f1poee { cursor: pointer; }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 68
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"xEQcZilqB":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"pSiJQpYxO":"title"}
 */
const FramersQlgOIOKw: React.ComponentType<Props> = withCSS(Component, css, "framer-dmZzi") as typeof Component;
export default FramersQlgOIOKw;

FramersQlgOIOKw.displayName = "Kezdőlap";

FramersQlgOIOKw.defaultProps = {height: 32, width: 68};

addPropertyControls(FramersQlgOIOKw, {pSiJQpYxO: {defaultValue: "Kezdőlap", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramersQlgOIOKw, [...sharedStyle.fonts])